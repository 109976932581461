.App {
  text-align: center;
  /*font-family: 'IBM Plex Mono', monospace;*/
  font-family: 'IBM Plex Sans', sans-serif;
/*  font-family: 'IBM Plex Serif', serif;*/
}

.App h5 {
  font-size: 12px;
  color:  rgba(255,255,255,0.5);
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*/

.App-header {
  background-color: #353950;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(40px + 6vmin);
  line-height: calc(40px + 8vmin);
  color: white;
  font-weight: 300;
  letter-spacing: 10;
}

.App-header h2 {
  font-family: 'IBM Plex Mono', monospace;
  font-weight: 300;
  font-size: 14px;
  line-height: 3vmin;
}

